@media(max-height: 700px) {
    .mid-card-block {
        width: 100%;
    }

    .mid-wrap {
        padding-top: 0 !important;
        padding-bottom: 2rem !important;
    }

    .mid-card-block {
        padding-top: 3rem;
    }
}

@media(max-width:1366px) {
    .hw-download-title {
        font-size: 1.4em;
        margin-bottom: 2rem !important;
    }

    .hc-logo img {
        max-width: 210px;
    }

    .home-card-block p {
        font-size: 1.1em;
        margin: 1.3rem 0 1.8rem;
    }

    .home-store-link img {
        max-width: 175px;
    }

    .home-app-screen img {
        max-width: 200px;
    }

    .deal-info-block .col-xxl-7.pr-3 {
        padding-right: 10px !important;
    }

    .waiting {
        text-align: end;
        margin-right: 35px;
    }
}

@media(max-width:1280px) {
    .common-left {
        display: none;
    }

    .common-right {
        width: 100%;
        padding-left: 0;
    }

    .mobile-menuicon {
        display: block;
    }

    .ham-menu-block {
        display: block !important;
        position: fixed;
        top: 0;
        left: -100%;
        bottom: 0;
        width: 270px;
        padding: 0;
        z-index: 11;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        transition: all 0.5s ease-in-out 0s;
    }

    .ham-menu-block.show {
        left: 0;
    }

    .ham-menu-block .common-left {
        display: block;
        height: 100%;
        border: none;
        padding: 15px 0;
        background: transparent;
    }

    .ham-menu-block li a {
        padding: 11px 20px;
    }

    .ham-menu-block li a.active {
        background: #F3F4F9;
        color: #0000FF;
        font-weight: 700;
    }

    .ham-menu-block .close-icon {
        padding: 10px 10px 0;
    }

    .overflow-bg {
        display: none;
        background: rgb(51 51 51 / 80%);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }

    .overflow-bg.show {
        display: block;
    }

    .common-left .custom-card {
        position: relative !important;
        top: 0;
        min-height: calc(100vh - 51px);
    }

    .logout-menu a {
        padding: 11px 20px;
    }

    .ham-menu-block .common-left {
        min-width: 100%;
    }

    .waiting {
        text-align: end;
        margin-right: 35px;
    }
}

@media(max-width:1199px) {
    .home-card-block p {
        font-size: 1em;
        margin: 1.2rem 0 1.6rem;
    }

    .home-app-screen img {
        max-width: 172px;
    }

    .white-right-box.join-box {
        padding: 40px 15px;
    }

    .deal-info-block .col-lg-6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .deal-info-block .col-lg-6+.col-lg-6 {
        margin-top: 10px;
    }

    .deal-info-block .dib-left-box {
        padding-right: 0 !important;
        border-right: none;
    }

    .waiting {
        text-align: end;
        margin-right: 35px;
    }
}

@media(max-width:992px) {
    .deal-info-block .col-lg-6 {
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
    }

    .deal-info-block .col-xl-5 {
        padding-top: 25px;
    }

    .deal-dsc-block .col-lg-5.border-l,
    .deal-dtl-block .col-lg-5.border-l {
        border-left: none !important;
        padding-top: 20px;
    }

    .deal-dsc-block .plr-30,
    .deal-dtl-block .plr-30 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .realtime-analysis-block {
        margin-top: 0 !important;
    }

    .about-info-list .md-title {
        font-size: 1em;
    }

    .home-card-block .card-body>.d-flex {
        display: block !important;
    }

    .home-card-block .w-40,
    .home-card-block .w-60 {
        width: 100% !important;
    }

    .home-card-block .w-40 {
        padding-top: 20px;
    }

    .deal-dtl-block .order-2 {
        order: inherit !important;
    }

    .deal-dtl-block>.pt-5 {
        padding-top: 1rem !important;
    }

    .detail-dtl-list {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .addround-tabs-btns .addround-btn {
        margin: 0 40px;
    }

    .addround-tabs-btns .addround-btn:after {
        width: 60px;
    }

    .waiting {
        text-align: end;
        margin-right: 35px;
    }
}

@media(max-width:767px) {
    body {
        font-size: 14px;
    }

    .container,
    .container-sm {
        max-width: 100%;
    }

    .logo-vr img {
        max-width: 90px;
    }

    .mid-card-block {
        padding-left: 15px;
        padding-right: 15px;
    }

    .vendor-info .sm-title.mb-5 {
        margin-bottom: 1.5rem !important;
    }

    .form-card {
        box-shadow: none;
    }

    .form-card .card-body {
        padding: 0 15px !important;
    }

    .form-card .card-body .sm-title.mt-4 {
        margin-top: 50px !important;
    }

    .menu ul li a {
        padding: 10px 7px;
    }

    .custom-form .sm-title br {
        display: none;
    }

    .theme-btn.w-25,
    .gray-btn.w-25,
    .gray-btn.idil-btn {
        width: calc(50% - 15px) !important;
    }

    .forms-header .lg-title {
        font-size: 1.1em;
    }

    .custom-form .custom-radio.radio-inline,
    .custom-form .custom-checkbox.d-inline-block {
        width: calc(50% - 17px);
    }

    .kyc-form-block {
        padding: 0 15px;
    }

    .detail-card .pt-5 {
        padding-top: 1.5rem !important;
    }

    .detail-card .pb-5 {
        padding-bottom: 1.5rem !important;
    }

    .gray-box .icon-md {
        min-height: 65px;
    }

    .gray-box .icon-md img {
        max-width: 60px;
        max-height: 60px;
    }

    .about-info-list .gray-box {
        padding: 25px 15px;
    }

    .mb-5.pb-5.border-b {
        margin-bottom: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .kyc-form-block .form-card {
        box-shadow: none;
    }

    .kyc-form-block {
        padding: 0;
    }

    .kyc-form-block .form-card .card-body {
        padding: 0 3px !important;
    }

    .process-step {
        height: 4px;
        margin: 0 4px;
        width: 30px;
    }

    .card-title.md-title {
        font-size: 1.05em;
        text-align: left !important;
        font-weight: 600;
    }

    .kyc-form-block {
        margin-bottom: 0 !important;
    }

    .custom-form label {
        font-size: 0.85em;
    }

    .react-radio label {
        font-size: 0.85em;
    }

    .back-arrow {
        padding: 0 15px 5px 0;
        display: inline-block;
    }

    .forms-header {
        top: 50px;
        padding-bottom: 0.5rem !important;
    }

    .react-checkbox input+span:before {
        width: 20px;
        height: 20px;
    }

    .react-checkbox input:checked+span:after {
        left: 5px;
        top: 7px;
    }

    .mxw-460 .card-title.md-title {
        padding-left: 25%;
    }

    .heading-formobile {
        display: block;
    }

    .heading-fordesktop {
        display: none;
    }

    .back-arrow img {
        max-width: 20px;
    }

    .kyc-form-block.pt-0 {
        padding-top: 0 !important;
    }

    .radio-col-2 .react-radio.radio-inline,
    .checkbox-col-2 .react-checkbox {
        width: 50%;
    }

    .footer-wrap {
        padding: 10px 0;
        font-size: 12px;
    }

    .realtime-logo {
        max-width: 41px;
        vertical-align: -4px;
        margin-left: 2px;
    }

    .home-card-block .card-body {
        padding: 40px 40px;
    }

    .home-card-block p {
        text-align: center;
    }

    .form-card .home-store-link img {
        max-width: 148px;
    }

    .hw-download-title {
        font-size: 1.2em;
        margin-bottom: 1.5rem !important;
    }

    .form-card .hc-logo img {
        max-width: 160px;
    }

    .form-card .home-app-screen img {
        max-width: 122px;
    }

    .choose-option-box .choose-option {
        max-width: 340px;
        margin: 0 auto;
    }

    .kyc-form-block .form-card.mt-4 {
        margin-top: 0px !important;
    }

    .pyp-block .custom-form .form-group {
        margin-bottom: 11px;
    }

    .custom-form .form-group .form-group {
        margin-bottom: 0;
    }

    .logo-login-class {
        display: block !important;
        text-align: center;
    }

    .custom-card {
        border: none;
    }

    .main-home-card {
        padding-top: 0 !important;

    }

    .main-home-card>.container {
        padding-left: 0;
        padding-right: 0;
    }

    .cc-common-card .cr-heading {
        border: none;
        padding-bottom: 0;
        padding: 17px 10px;
    }

    .cc-common-card>.pt-4 {
        padding-top: 15px !important;
    }

    .common-right .custom-card>.ps-5.pe-5 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .main-home-card .form-card.ps-4.pe-4 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .dtl-info .gray-box {
        padding: 7px 3px;
    }

    .white-right-box.join-box {
        padding: 15px 0 0 !important;
        position: relative;
        margin-top: 10px;
    }

    .white-right-box.join-box:before {
        content: "";
        border-top: solid 1px #eee9fc;
        position: absolute;
        left: -15px;
        right: -15px;
        top: 0;
    }

    .extra-link-actions {
        position: relative;
        text-align: right;
    }

    .white-right-box.join-box .row {
        margin-left: -5px;
        margin-right: -5px;
    }

    .white-right-box.join-box .col {
        padding-left: 5px;
        padding-right: 5px;
    }

    .d-green-btn,
    .green-btn,
    .red-btn,
    .theme-btn,
    .blue-btn,
    .close-btn {
        height: 36px;
        line-height: 35px;
        font-size: 13px;
        border-width: 1px;
    }

    .close-btn {
        padding: 0px 10px;
    }

    .change-amount-btn,
    .confirm-amount-btn {
        line-height: normal;
    }

    .dtl-deal-info-block {
        border-radius: 15px;
        box-shadow: 0px 4px 20px rgba(221, 209, 255, 0.6);
        padding: 0 15px;
        margin-top: 10px;
        border: solid 1px #eee9fc;
    }

    .startup-profile-header .d-flex {
        display: block !important;
        text-align: center;
    }

    .startup-profile-header .circle-box {
        margin: 0 auto;
    }

    .startup-profile-header .ml-15 {
        margin-left: 0 !important;
    }

    .startup-profile-header .text-start {
        text-align: center !important;
    }

    .startup-profile-header {
        position: relative;
        top: inherit;
    }

    .header-mobile {
        position: sticky;
        top: 79px;
        z-index: 2;
        background: #fff;
        padding: 0 15px;
        margin-bottom: 0;
    }

    .header-mobile .back-btn {
        padding: 15px 15px 15px 0;
        font-weight: 900;
        font-size: 1.1em;
        color: #333333;
    }

    .startup-profile-header.border-b {
        border-bottom: none;
    }

    .header-mobile .back-btn img {
        margin-right: 7px;
        max-width: 18px;
        vertical-align: -2px;
        display: inline-block;
    }

    .home-tab-list li a {
        padding: 12px 15px;
        font-size: 13px;
    }

    .my-profile-card li .btn-sm.me-4 {
        margin-right: 0.7rem !important;
        font-size: 11px;
    }

    .hml-title {
        width: auto;
    }

    .my-profile-card li a {
        height: 56px;
    }

    .theme-btn.btn-sm,
    .blue-btn.btn-sm {
        padding: 0 15px;
    }

    .profile-circle-flex {
        display: block !important;
        text-align: center;
    }

    .profile-circle-flex .ml-15 {
        margin-left: 0 !important;
    }

    .profile-circle-flex .text-start {
        text-align: center !important;
    }

    .profile-circle-flex .profile-circle-box {
        margin-bottom: 10px;
    }

    .header-desktop {
        display: none !important;
    }

    .di-sub-value {
        font-size: 14px;
        font-weight: 600;
    }

    .deal-dsc-block .md-title {
        font-weight: 500;
    }

    .blue-btn.btn-sm {
        font-size: 12px;
    }

    .kyc-form-block .card-title.md-title {
        font-size: 0.9em;
        font-weight: 400;
    }

    .pfr-table tr td {
        font-size: 14px;
    }

    .blue-box .col-md-4 {
        border-left: none !important;
    }

    .first-box {
        border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
    }

    .blue-box .col-md-4.third-box {
        border-left: 1px dashed rgba(255, 255, 255, 0.4) !important;
    }

    .blue-box {
        padding: 10px 30px;
    }

    .distribution-block {
        margin-bottom: 15px;
    }

    .addround-tabs-btns .addround-btn {
        margin: 0 22px;
    }

    .addround-tabs-btns .addround-btn:after {
        width: 30px;
    }

    .addround-tabs-btns.pt-5 {
        padding-top: 1.5rem !important;
    }

    .addround-tabs-btns .addround-btn {
        white-space: nowrap;
    }

    .addround-tabs-btns {
        overflow-x: auto;
        width: 100%;
    }

    .addround-step-count {
        width: 28px;
        height: 28px;
        margin-right: 7px;
        line-height: 27px;
    }

    .rfl-btn-flex {
        display: block !important;
    }

    .rfl-btn-box .transparent-btn {
        padding-left: 0;
    }

    .circle-box {
        width: 46px;
        height: 46px;
        min-width: 46px;
    }

    .favourite-box {
        align-items: start !important;
    }

    .waiting {
        text-align: end;
        margin-right: 30px;
    }
}

@media(max-width:575px) {
    .kyc-success-block .card-body .d-flex {
        flex-wrap: wrap;
    }

    .kyc-success-block .card-body .d-flex .w-50 {
        width: 100% !important;
        padding-left: 0 !important;
    }

    .kyc-success-block .d-flex .w-50+.w-50 {
        margin-top: 15px;
    }

    .dtl-info .col-sm-4+.col-sm-4 {
        margin-top: 15px;
    }

    .uploaded-imgs-block .uploaded-box img {
        max-width: 100%;
    }

    .menu ul li a {
        font-size: 0.95em;
        padding: 10px 10px;
    }

    .forms-header .row .col-2:first-child {
        width: 100%;
    }

    .forms-header .col-md-8 {
        width: calc(100% - 90px);
    }

    .forms-header .col-md-8+.col-2 {
        width: 90px;
    }

    .forms-header .lg-title {
        text-align: left !important;
    }

    .back-arrow img {
        max-width: 20px;
        display: block;
    }

    .form-card .theme-btn.w-25,
    .form-card .gray-btn.w-25,
    .form-card .gray-btn.idil-btn {
        width: 100% !important;
    }

    .form-card .theme-btn.ml-15 {
        margin-left: 0 !important;
        margin-top: 15px !important;
    }

    .rr-col-2 .react-radio,
    .rr-col-2 .react-checkbox {
        width: calc(50% - 15px);
    }

    .step-process .d-inline-flex {
        display: flex !important;
        margin: 0 -4px;
    }

    .step-process {
        margin-top: 1rem !important;
        margin-bottom: 0.6rem !important;
    }

    .step-process .process-step {
        width: calc(14.28% - 8px);
    }

    .header-block img {
        max-height: 29px;
    }

    .profile-circle-header {
        width: 34px;
        height: 34px;
    }

    .profile-circle-header img {
        max-height: 34px;
    }

    .header-block {
        padding: 10px 0;
    }

    .forms-header {
        top: 50px;
        padding-bottom: 0.5rem !important;
        padding-top: 1rem !important;
    }

    .choose-option-heading.forms-header .col-md-8 {
        width: 100%;
    }

    .choose-option-heading.forms-header .lg-title {
        text-align: center !important;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .home-tab-list li+li {
        margin-top: 14px;
    }

    .white-right-box {
        padding: 15px;
    }

    .deal-list-block .common-right>.custom-card {
        border: none;
        padding: 0 !important;
    }

    .deal-list-block.pt-5.pb-5 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important;
    }

    .mobile-menuicon {
        margin-left: 5px;
    }

    .user-dropdown .ud-arrow {
        width: 10px;
        height: 6px;
    }

    .ud-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 69px;
    }

    .header-wrap .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .header-mobile {
        top: 65px;
    }

    .dtl-top-info .circle-box {
        width: 80px;
        height: 80px;
        min-width: 80px;
    }

    .dtl-top-info .md-title {
        font-size: 1.1em;
    }

    .mb-6 {
        margin-bottom: 3px !important;
    }

    .faq-tag img {
        max-height: 25px;
    }

    .startup-profile-header .ela-action .bi {
        font-size: 19px;
    }

    .deal-info-block .col-xl-5 {
        padding-top: 0;
    }

    .deal-info-block .col-lg-6+.col-lg-6 {
        margin-top: 0;
    }

    .rating-stars .bi {
        font-size: 16px;
    }

    .dtl-top-info .mb-3 {
        margin-bottom: 0.8rem !important;
    }

    .dtl-top-info .md-title.font-1em {
        font-weight: 600 !important;
    }

    .pfr-table tr th,
    .pfr-table tr td {
        font-size: 14px;
    }

    .addround-md-title {
        width: auto;
        text-align: right !important;
    }

    .addround-content .ps-5.pe-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .pt-4.pb-4.ps-5.pe-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .addround-flex {
        justify-content: space-between;
    }

    .waiting {
        text-align: end;
        margin-right: 30px;
    }
}

@media(max-width:480px) {

    .custom-form .custom-radio.radio-inline,
    .custom-form .custom-checkbox.d-inline-block {
        width: 94%;
    }

    .pdficon img {
        max-width: 33px !important;
    }

    .form-card .sm-title br {
        display: none;
    }

    .form-card .join-box .sm-title br {
        display: block;
    }

    .w-360 {
        width: calc(100% - 30px);
    }

    .uploaded-imgs-block .uploaded-box {
        width: 100%;
    }

    .radio-col-2 .react-radio.radio-inline,
    .checkbox-col-2 .react-checkbox {
        width: 100%;
    }

    .react-radio.radio-inline {
        width: 100%;
    }

    .d-green-btn br {
        display: none;
    }

    .dtl-info .col-info-box {
        width: 33.33%;
    }

    .dtl-info-box {
        width: 33.33%;
    }

    .custom-form select.form-control {
        font-size: 11px;
    }

    .extra-link-actions .ela-action .bi {
        font-size: 19px;
    }

    .extra-link-actions .faq-tag img {
        max-width: 22px;
    }

    .extra-link-actions .ela-action .bi.bi-eye {
        font-size: 23px;
    }

    .dtl-info.d-flex.justify-content-between {
        justify-content: inherit !important;
    }

    .dtl-info {
        gap: 8px;
    }

    .w-180 {
        width: calc(50% - 15px);
    }

    .pt-4.pb-4.ps-5.pe-5.mx-4 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .small-btn,
    .fill-gray-btn {
        padding-left: 8px;
        padding-right: 8px;
        height: 28px !important;
        line-height: 25px !important;
        text-decoration: none !important;
        font-size: 0.7em !important;
    }

    .mb-mxw-400 .w-50.px-3 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .waiting {
        text-align: end;
        margin-right: 20px;
    }
}

@media(max-width:420px) {
    .home-store-link img {
        max-width: 100%;
    }

    .header-block {
        min-height: 62px;
    }

    .change-amount-btn br,
    .confirm-amount-btn br {
        display: block;
    }

    .dtl-info .col-info-box.me-2,
    .dtl-info .dtl-info-box.me-2 {
        margin-right: 0 !important;
    }

    .dtl-info .col-info-box,
    .dtl-info .dtl-info-box {
        width: 33.33%;
    }

    .dtl-info .col-info-box.me-2:last-child,
    .dtl-info .dtl-info-box.me-2:last-child {
        margin-right: 0 !important;
    }

    .waiting {
        text-align: end;
        margin-right: 20px;
    }
}

@media(max-width:380px) {
    .menu ul li a {
        padding: 10px 5px;
        font-size: 0.85em;
    }

    .theme-btn.w-50 {
        width: 100% !important;
    }

    .vertical-tabs.custom-tabs .nav-tabs {
        width: 140px;
    }

    .vertical-tabs.custom-tabs .tab-content {
        width: calc(100% - 140px);
    }

    .waiting {
        text-align: end;
        margin-right: 20px;
    }
}