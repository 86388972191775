@import url('assets/css/style.css');
@import url('assets/css/responsive.css');

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
