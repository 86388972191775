@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

html,
body {
    width: 100%;
    min-height: 100%;
    font-weight: 400;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
a:hover {
    color: #333;
    text-decoration: none;
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    color: #000000;
}

input,
select {
    font-family: 'Lato', sans-serif;
}

p {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4em;
    text-align: left;
    color: #948fb5;
}

img {
    max-width: 100%;
}

.header-wrap {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;
    background: #FFFFFF;
    border-bottom: 1px solid #0000FF;
}

.header-block {
    padding: 12px 0;
    text-align: center;
}

.header-block img {
    max-height: 46px;
}

.lg-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.4em;
    line-height: 1.4em;
    text-align: center;
    color: #333333;
}

.sm-title {
    font-weight: 400;
    font-size: 0.9em;
    line-height: 1.3em;
    text-align: center;
    color: #948fb5;
}

.custom-form .form-group {
    margin-bottom: 19px;
}

.custom-form label {
    font-size: 0.9em;
    margin-bottom: 4px;
    font-weight: 700;
    color: #948FB5;
}

.custom-form label.black-text {
    font-size: 1em;
}

.custom-form .form-control {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 8px;
    padding: 14px 20px;
    border: none;
}

.custom-form select.form-control {
    background: #f6f5fd url(../img/down-arrow.svg) no-repeat calc(100% - 15px) center;
    background-size: 13px;
    line-height: normal;
}

.custom-form textarea.form-control {
    resize: none;
    height: 105px;
}

.custom-form .form-control:focus {
    outline: none;
    box-shadow: none;
}

.theme-btn {
    background: #ff6624;
    border: solid 2px #ff6624;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1em;
    line-height: 17px;
    height: 46px;
    line-height: 42px;
    color: #fff;
    transition: all 0.5s ease-in-out 0s;
}

.theme-btn:hover {
    background: #fff;
    color: #ff6624;
    border: solid 2px #ff6624;
}

.blue-btn {
    background: #0000ff;
    border: solid 2px #0000ff;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1em;
    line-height: 17px;
    height: 46px;
    line-height: 42px;
    color: #fff;
    transition: all 0.5s ease-in-out 0s;
    padding: 0 10px;
}

.blue-btn:hover {
    background: #fff;
    color: #0000ff;
    border: solid 2px #0000ff;
}

a.blue-btn {
    display: inline-block;
}

.gray-btn {
    background: transparent;
    border: solid 2px #948fb5;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1em;
    line-height: 17px;
    padding: 15px 20px;
    color: #948fb5;
    transition: all 0.5s ease-in-out 0s;
}

.gray-btn:hover {
    background: #948fb5;
    color: #333;
}

.mxw-600 {
    max-width: 600px;
    margin: 0 auto;
}

.mxw-750 {
    max-width: 750px;
    margin: 0 auto;
}

.mxw-400 {
    max-width: 400px;
    margin: 0 auto;
}

.form-card .card-body {
    padding: 30px 45px;
}

.footer-wrap {
    background: #ffffff;
    border-top: 1px solid #eee9fc;
    padding: 15px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #948fb5;
    text-align: center;
}

.orange-text {
    color: #ff6624 !important;
}

.myPinCode input {
    width: 52px !important;
    height: 52px !important;
    text-align: center;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 8px;
    padding: 14px 20px;
    border: none;
    box-shadow: none;
    outline: none;
}

.myPinCode input:focus {
    border: none;
    box-shadow: none;
}

.mid-wrap {
    min-height: calc(100vh - 161px);
    position: relative;
    /* z-index: 0; */
}

.mid-card-block {
    width: 100%;
    padding: 0 0 0;
}

.form-card {
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    border: none;
    background: #FFFFFF;
    border-radius: 4px;
}

.blue-text {
    color: #0000ff !important;
}

.border-b {
    border-bottom: 1px solid #eee9fc;
}

.border-l {
    border-left: 1px solid #eee9fc;
}

.ml-10 {
    margin-left: 10px !important;
}

.md-title {
    font-weight: 700;
    font-size: 1.1em;
    color: #333333;
}

.font-0-8em {
    font-size: 0.8em !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.circle-box {
    width: 60px;
    height: 60px;
    background: #e0ede7;
    border-radius: 50%;
    position: relative;
    min-width: 60px;
    overflow: hidden;
    border: solid 1px #ededed;
}

.circle-box img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
}

.ml-15 {
    margin-left: 15px !important;
}

.black-text {
    color: #333333 !important;
}

.gray-box {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 10px;
    padding: 15px;
}

.gray-box .icon {
    min-height: 32px;
    margin-bottom: 1px;
}

.gray-box .icon img {
    max-height: 20px;
}

.dtl-info .gray-box {
    padding: 7px 15px;
}

.dtl-info .sm-title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #333333;
}

.green-text {
    color: #0d942b !important;
}

.call-dtl-box {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 10px;
    padding: 10px 5px 10px 15px;
}

.recording-icon {
    background: url(../img/recording.svg) no-repeat center center;
    background-size: 100%;
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: -3px;
    margin-right: 3px;
}

.theme-btn:hover .recording-icon {
    background: url(../img/orange-recording.svg) no-repeat center center;
    background-size: 100%;
}

.pitch-recording-btn {
    text-transform: inherit;
    font-size: 0.7em;
    font-weight: 700;
    padding: 10px 24px;
}

.pl-6 {
    padding-left: 6px !important;
}

.red-btn {
    background: rgba(211, 47, 47, 0.1);
    border: 1px solid rgba(211, 47, 47, 0.3);
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #d32f2f;
    height: 46px;
    line-height: 44px;
}

.red-btn.is-not-interested {
    background: #d32f2f;
    border-color: #d32f2f;
    color: #fff;
}

.green-btn {
    background: rgba(13, 148, 43, 0.1);
    border: 1px solid rgba(13, 148, 43, 0.3);
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #0d942b;
    height: 46px;
    line-height: 44px;
}

.green-btn.is-interested {
    background: #0d942b;
    border-color: #0d942b;
    color: #fff;
}

.d-green-btn {
    background: #0d942b;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    height: 46px;
    border: none;
    line-height: 44px;
}

.d-green-btn:disabled {
    opacity: 0.5;
    color: rgb(255 255 255 / 50%);
}

.pitch-call-action .d-green-btn {
    /* left: 50%; */
    transform: translate(25%, 0);
    position: absolute;
    width: 50%;
}

.d-green-btn img {
    vertical-align: -2px;
}

.d-green-btn span {
    padding: 0 15px;
}

.mnw-165 {
    min-width: 200px !important;
}

.lh-1-5em {
    line-height: 1.5em !important;
}

.font-1-2em {
    font-size: 1.1em !important;
}

.menu ul {
    padding: 0;
    margin: 0;
}

.menu ul li a {
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 700;
}

.menu ul li:last-child a {
    padding-right: 0;
}

.menu ul li a.active {
    color: #ff6624;
}

.vendor-info img {
    max-width: 100px;
}

.pl-3 {
    padding-left: 3rem !important;
}

.pr-3 {
    padding-right: 3rem !important;
}

.font-1em {
    font-size: 1em !important;
}

.fw-600 {
    font-weight: 400 !important;
}

.white-right-box {
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 40px 35px;
}

.desc {
    margin-top: 10px;
}

.desc p {
    font-weight: 400;
    color: #333;
    font-size: 1em;
}

.social-icons a {
    margin-right: 10px;
}

.social-icons a img {
    max-width: 24px;
    width: 24px;
}

.video-thumb img {
    width: 100%;
    max-width: 340px;
}

.deal-dsc-block .card {
    border-color: #eee9fc;
}

.deal-dsc-block .card-body {
    padding: 25px;
}

.custom-table tr th {
    font-weight: 400;
    font-size: 1em;
    color: #333333;
    padding: 3px;
}

.custom-table tr td {
    font-size: 1em;
    font-weight: 400;
    color: #948fb5;
    padding: 4px 3px;
}

.realtime-logo {
    max-width: 58px;
    vertical-align: -7px;
    margin-left: 3px;
    border-radius: 2px;
}

.rating-count {
    font-weight: 700;
    font-size: 2.5em;
}

.rating-stars img {
    display: inline-block;
}

.plr-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.realtime-analysis-block .custom-table tr td {
    padding: 6px 3px;
}

.deal-flow-item {
    padding: 0 0 30px;
    position: relative;
    padding-left: 30px;
}

.deal-flow-item:last-child {
    margin-bottom: 0;
}

.deal-flow-item:before {
    content: '';
    position: absolute;
    left: 8px;
    top: 4px;
    border-left: 2px dashed #948fb5;
    bottom: 0;
}

.deal-flow-item:last-child:before {
    display: none;
}

.deal-flow-item.completed:after {
    background: #0d942b;
}

.deal-flow-item.inprogress:after {
    background: #ff6624;
}

/* .deal-flow-item:after {
    content: '';
    position: absolute;
    left: -8px;
    top: 2px;
    background: #948fb5;
    width: 14px;
    height: 14px;
    border-radius: 50%;
} */

.back-arrow img {
    max-width: 24px;
    display: block;
}

.pl-15 {
    padding-left: 15px !important;
}

.kyc-form-block {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
}

.kyc-form-block .form-card {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.custom-radio [type='radio']:checked,
.custom-radio [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

.custom-radio [type='radio']:checked+label,
.custom-radio [type='radio']:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #333;
    font-size: 0.85em;
}

.custom-radio [type='radio']:checked+label:before,
.custom-radio [type='radio']:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
}

.custom-radio [type='radio']:checked+label:before {
    border: 1px solid #ff6624;
}

.custom-radio [type='radio']:checked+label:after,
.custom-radio [type='radio']:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff6624;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom-radio [type='radio']:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.custom-radio [type='radio']:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.radio-inline {
    display: inline-block;
}

.custom-checkbox .styled-checkbox {
    position: absolute;
    opacity: 0;
}

.custom-checkbox .styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    color: #333333;
    font-size: 0.85em;
}

.custom-checkbox .styled-checkbox+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 22px;
    height: 22px;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 2px;
    position: absolute;
    top: -1px;
    left: 0;
}

.custom-checkbox .styled-checkbox:checked+label:before {
    background: #f35429;
    border: 1px solid #f35429;
}

.custom-checkbox .styled-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
    transform: rotate(45deg);
}

.xs-title {
    font-weight: 700;
    font-size: 0.7em;
    color: #948fb5;
}

.custom-upload {
    position: relative;
    overflow: hidden;
}

.custom-upload .custom-upload-btn {
    background: rgba(0, 0, 255, 0.1);
    border: 1px solid rgba(0, 0, 255, 0.2);
    border-radius: 8px;
    color: #0000ff;
    font-weight: 700;
    font-size: 1em;
    padding: 10px 15px;
    width: 100%;
}

.custom-upload input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.uploaded-imgs-block {
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.uploaded-box {
    position: relative;
    margin-right: 10px;
}

.uploaded-box>img {
    width: 100%;
    border-radius: 4px;
}

.cross-icon {
    position: absolute;
    right: -11px;
    top: -13px;
    cursor: pointer;
}

.mr-15 {
    margin-right: 15px !important;
}

.custom-form .form-control.calendar {
    background: #f6f5fd url(../img/calendar.svg) no-repeat calc(100% - 15px) center;
    background-size: 20px;
    padding-right: 45px;
}

/* Date Picker */
.datepicker table tr td,
.datepicker table tr th {
    text-align: center;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    border: none;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover {
    background-image: none;
    background-color: #ff6624 !important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover {
    background-image: none;
    background-color: #0000ff !important;
    color: #fff;
}

.mxw-360 {
    max-width: 360px;
    margin: 0 auto;
    line-height: 1.4em;
}

.success-menu {
    text-align: center;
}

.success-menu ul {
    display: inline-flex;
}

.success-menu ul li a {
    padding: 0 25px;
    display: block;
    color: #333333;
    font-weight: 700;
    font-size: 1em;
    text-transform: uppercase;
    text-decoration-line: underline;
    line-height: 1em;
}

.success-menu ul li:last-child {
    border-left: solid 1px #d9d9d9;
}

.mxw-800 {
    max-width: 750px;
    margin: 0 auto;
}

.pd-50 {
    padding: 50px !important;
}

.doc-info-list {
    padding-left: 15px;
}

.doc-info-list li {
    padding: 1px 0;
    list-style-type: disc;
    font-size: 0.9em;
}

.kyc-status-tracking .deal-flow-item:after {
    top: 2px;
    transform: inherit;
}

.kyc-status-tracking .deal-flow-item.disabled {
    opacity: 0.5;
}

.kyc-status-tracking .deal-flow-item.pending:after {
    background: #d32f2f;
}

.transparent-orange-btn {
    background: #ffffff;
    border: 1px solid #ff6624;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.7em;
    text-align: center;
    text-transform: uppercase;
    color: #ff6624;
    padding: 8px 15px 7px 15px;
    transition: all 0.5s ease-in-out 0s;
}

.transparent-orange-btn:hover {
    background: #ff6624;
    border: 1px solid #ff6624;
    color: #fff;
}

.transparent-btn {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.7em;
    text-align: center;
    color: #333;
    padding: 2px 5px 2px 5px;
    transition: all 0.5s ease-in-out 0s;
}

.transparent-btn:hover {
    color: #333;
}

.lgray-btn {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 8px;
    color: #948fb5;
    font-weight: 700;
    font-size: 1em;
    padding: 10px 15px;
    width: 100%;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out 0s;
}

.lgray-btn:hover {
    color: #fff;
    background: #333;
    border: 1px solid #333;
}

.msg-icon {
    background: url(../img/message-icon.svg) no-repeat center center;
    background-size: 100%;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: -4px;
    margin-right: 2px;
}

.lgray-btn:hover .msg-icon {
    background: url(../img/message-icon-hover.svg) no-repeat center center;
}

.custom-form .custom-radio.radio-inline,
.custom-form .custom-checkbox.d-inline-block {
    width: 33.33%;
    margin-bottom: 11px;
}

.custom-dropdown .dropdown-toggle:focus {
    outline: none;
    box-shadow: none;
}

.custom-dropdown .dropdown-toggle::after {
    display: none;
}

.custom-dropdown .dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(186, 184, 184, 0.6);
    border-radius: 4px;
    border: none;
    padding: 0;
}

.custom-dropdown .dropdown-menu li+li {
    border-top: solid 1px #eee9fc;
}

.custom-dropdown .dropdown-menu .dropdown-item {
    font-weight: 700;
    font-size: 1em;
    color: #333333;
    padding: 11px 20px;
}

.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active,
.custom-dropdown .dropdown-item:hover,
.custom-dropdown .dropdown-item:focus {
    background-color: #ff6624;
    color: #fff;
}

.custom-dropdown .dropdown-menu li:first-child .dropdown-item {
    border-radius: 4px 4px 0 0;
}

.custom-dropdown .dropdown-menu li:last-child .dropdown-item {
    border-radius: 0 0 4px 4px;
}

.share-modal .modal-dialog {
    max-width: 374px;
}

.social-share {
    text-align: center;
}

.social-share a {
    margin-right: 15px;
    width: 45px;
    height: 45px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: solid 1px #333;
    position: relative;
}

.social-share a span {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    transition: all 0.5s ease-in-out 0s;
}

.social-share .fb-box {
    border-color: #3d6ad6;
}

.social-share .fb-box span {
    background: url(../img/facebook.png) no-repeat center center;
    background-size: 100%;
}

.social-share .fb-box:hover {
    background: rgb(61 106 214 / 10%);
}

/* .social-share .fb-box:hover span {
    background: url(../img/facebook.png) no-repeat center center;
    background-size: 100%;
} */
.social-share .tw-box {
    border-color: #03a9f4;
}

.social-share .tw-box span {
    background: url(../img/twitter.png) no-repeat center center;
    background-size: 100%;
}

.social-share .tw-box:hover {
    background: rgb(3 169 244 / 10%);
}

/* .social-share .tw-box:hover span {
    background: url(../img/twitter.png) no-repeat center center;
    background-size: 100%;
} */
.social-share .ins-box {
    border-color: #ff7543;
}

.social-share .ins-box span {
    background: url(../img/instagram.png) no-repeat center center;
    background-size: 100%;
}

.social-share .ins-box:hover {
    background: rgb(255 117 67 / 10%);
}

/* .social-share .ins-box:hover span {
    background: url(../img/instagram.png) no-repeat center center;
    background-size: 100%;
} */
.social-share .wh-box {
    border-color: #29a71a;
}

.social-share .wh-box span {
    background: url(../img/whatsapp.png) no-repeat center center;
    background-size: 100%;
}

.social-share .wh-box:hover {
    background: rgb(41 167 26 /10%);
}

/* .social-share .wh-box:hover span {
    background: url(../img/whatsapp.png) no-repeat center center;
    background-size: 100%;
} */
.custom-modal .modal-content {
    border: none;
    border-radius: 8px;
}

.custom-modal .modal-body {
    padding: 35px;
}

.document-list li {
    padding: 20px 0;
}

.document-list li+li {
    border-top: 1px solid #eee9fc;
}

.view-icon img {
    max-width: 20px;
    display: block;
}

.ml-auto {
    margin-left: auto !important;
}

.deal-heading {
    width: calc(100% - 300px);
    text-align: center;
}

.gray-box .icon-md {
    min-height: 80px;
}

.gray-box .icon-md img {
    max-width: 70px;
    max-height: 70px;
}

.common-content p {
    text-align: center;
}

.about-info-list .gray-box {
    padding: 35px 20px;
}

.about-info-list .md-title {
    min-height: 60px;
}

.accordion-item {
    border: none;
    margin-bottom: 15px;
}

.accordion-button {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    background: #f6f5fd;
    border-radius: 4px;
}

.accordion-button:not(.collapsed) {
    background: #f6f5fd;
    color: #333333;
    box-shadow: none;
    border-radius: 4px 4px 0 0;
}

.accordion-button:focus {
    outline: none;
    box-shadow: none;
}

.accordion-collapse {
    border: 1px solid #eee9fc;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.accordion-collapse p {
    font-size: 1em;
    list-style-type: disc;
    padding: 6px 0;
    color: #948fb5;
}

.accordion-collapse ul {
    padding-left: 30px;
}

.accordion-collapse ul li {
    font-size: 1em;
    list-style-type: disc;
    padding: 6px 0;
    color: #948fb5;
}

.accordion-button::after {
    background: url(../img/faq-down-arrow.svg) no-repeat center center;
    background-size: 100%;
    width: 14px;
    height: 14px;
}

.accordion-button:not(.collapsed)::after {
    background: url(../img/faq-down-arrow.svg) no-repeat center center;
    background-size: 100%;
}

.pitch-call-action .d-green-btn {
    transition: all 0.5s ease-in-out 0s;
    transform: translate(50%, 0);
}

.pitch-call-action .d-green-btn.pitch_no {
    transform: translate(0, 0);
}

.pitch-call-action .d-green-btn.pitch_yes {
    transform: translate(100%, 0);
}

.toggle_chk {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.lg-title span {
    position: relative;
}

.lg-title span:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translate(-50%, 0);
    width: 60px;
    height: 3px;
    background: #0000ff;
    border-radius: 3px;
}

.modal-heading-flex .md-title {
    width: calc(100% - 28px);
    text-align: center;
}

.document-modal .modal-dialog {
    max-width: 550px;
}

.forms-header {
    position: sticky;
    top: 71px;
    z-index: 9;
    background: #fff;
}

.startup-profile-header {
    background: #fff;
}

.common-modal.show {
    display: block !important;
    background: rgb(0 0 0 / 50%);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.deal-flow-circle {
    position: absolute;
    left: 2px;
    top: 3px;
    background: #0d942b;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.modal-body {
    padding: 25px;
}

.loading-box {
    min-height: calc(100vh - 126px);
    width: 100%;
    text-align: center;
}

.loading-text {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
}

.instructions-table {
    table-layout: fixed;
    font-size: 16px;
}

.instructions-table tr {
    height: 50px;
    vertical-align: middle;
}

.white-btn {
    background: #FFFFFF;
    border: 1px solid #EEE9FC;
    font-weight: 400;
    font-size: 0.9em;
    text-align: center;
    color: #948FB5;
    display: inline-block;
    padding: 12px 15px;
}

.w-360 {
    width: 360px;
}

.mt-30 {
    margin-top: 30px !important;
}

.process-step {
    background: #EEE9FC;
    border-radius: 50px;
    width: 38px;
    height: 5px;
    display: inline-block;
    margin: 0 5px;
}

.process-step.selected {
    background: #948FB5;
}

.process-step.active {
    background: #10B53E;
}

.required {
    position: relative;
    padding-right: 8px;
}

.required:after {
    content: "*";
    position: absolute;
    right: 0;
    color: #DF3D0F;
}

.forms-header.border-b {
    border-bottom: none !important;
}


.react-checkbox label {
    margin-bottom: 0;
    display: block;
}

.react-checkbox input {
    position: absolute;
    opacity: 0;
}

.react-checkbox input+span {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    color: #333333;
    display: inline-block;
}

.react-checkbox input+span:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 22px;
    height: 22px;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 2px;
    position: absolute;
    top: -1px;
    left: 0;
}

.react-checkbox input:checked+span:before {
    background: #f35429;
    border: 1px solid #f35429;
}

.react-checkbox input:checked+span:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
    transform: rotate(45deg);
}

.rr-col-2 .react-checkbox {
    display: inline-block;
    width: 33.33%;
    margin-bottom: 8px;
    margin-top: 4px;
    margin-right: 15px;
}


.react-radio [type='radio']:checked,
.react-radio [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

.react-radio label {
    color: #333;
    font-weight: 700;
}

.react-radio [type='radio']:checked+span,
.react-radio [type='radio']:not(:checked)+span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}

.react-radio [type='radio']:checked+span:before,
.react-radio [type='radio']:not(:checked)+span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
}

.react-radio [type='radio']:checked+span:before {
    border: 1px solid #ff6624;
}

.react-radio [type='radio']:checked+span:after,
.react-radio [type='radio']:not(:checked)+span:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff6624;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-radio [type='radio']:not(:checked)+span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.react-radio [type='radio']:checked+span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.rr-col-2 .react-radio {
    display: inline-block;
    width: 33.33%;
    margin-bottom: 8px;
    margin-top: 4px;
}

.mt-10 {
    margin-top: 10px !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.mxw-520 {
    max-width: 520px;
    margin: 0 auto;
}

.form-card .theme-btn {
    padding: 0 20px;
}

.join-box {
    font-family: 'Lato', sans-serif;
    box-shadow: none;
    padding: 0;
}

.join-box .theme-btn {
    padding: 0 15px;
    font-size: 12px;
}

.btn-340 {
    max-width: 340px;
}

.mt-25 {
    margin-top: 25px;
}

.myPinCode {
    max-width: 280px;
    margin: 0 auto;
}


.orange-text {
    color: #ff6624;
}

.resend-btn button {
    border: none;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #948FB5;
}

.resend-btn {
    text-align: center;
    display: block !important;
}

.kyc-form-block {
    margin-bottom: 3rem;
}

.choose-option {
    padding-left: 10%;
}

.choose-option .radio-inline {
    display: block !important;
    padding: 12px 0;
}

.mxw-460 {
    max-width: 460px;
    margin: 0 auto;
}

.heading-formobile {
    display: none;
}

.kyc-form-block.pt-0 {
    padding-top: 1.5rem !important;
}

.choose-option .react-radio [type='radio']:checked+span,
.choose-option .react-radio [type='radio']:not(:checked)+span {
    font-weight: 700;
    text-transform: uppercase;
    color: #948FB5;
    letter-spacing: 1.62px;
    padding-left: 43px;
}

.fw-700 {
    font-weight: 700 !important;
}

.blank-link {
    color: #948FB5;
}

.font-16 {
    font-size: 16px !important;
}

.radio-col-2 .react-radio.radio-inline,
.checkbox-col-2 .react-checkbox {
    width: 35%;
    margin: 4px 0;
    display: inline-block;
}

.rradio-mb .react-checkbox {
    margin: 5px 0 7px;
}

.error-box {
    background: #fff;
    padding: 22px;
    max-width: 370px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    text-align: center;
    font-weight: 400;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background: #ff6624;
    border: solid 2px #ff6624;
    color: #fff;
    opacity: 0.5;
}

.checkbox-col-2 .singleline .react-checkbox {
    width: 100%;
}

.forms-header .lg-title {
    text-transform: capitalize;
}

.change-btn {
    border: none;
    background: transparent;
    color: #ff6624;
    padding: 0;
    font-size: 13px;
    padding-left: 5px;
}

.pdficon {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    align-items: center;

}

.pdficon img {
    max-width: 35px;
}

.pdfname {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: calc(100% - 45px);
}

.typepdf .uploaded-box {
    width: 93% !important;
}

.typepdf .uploaded-box .cross-icon {
    top: 0;
}

.home-card-block {
    max-width: 100%;
}

.home-card-block .card-body {
    padding: 60px 80px;
}

.home-card-block .w-60 {
    width: 60%;
}

.home-card-block .w-40 {
    width: 40%;
}

.hw-download-title {
    font-weight: 400;
    font-size: 1.8em;
    line-height: 1.2em;
    color: #0000FF;
    margin-bottom: 2.5rem !important;
}

.hc-logo img {
    max-width: 260px;
}

.home-card-block p {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.4em;
    color: #948FB5;
    margin: 1.5rem 0 2rem;
}

.home-store-link img {
    max-width: 200px;
}

.home-app-screen img {
    max-width: 220px;
}

.mxw-650 {
    max-width: 650px;
}

.home-tab-list {
    padding: 22px 0 0;
    margin: 0 auto;
}

.home-tab-list li+li {
    margin-top: 28px;
}

.home-tab-list li a {
    background: #FFFFFF;
    border: 2.53471px solid #EEE9FC;
    box-shadow: 0px 5.06942px 25.3471px rgba(221, 209, 255, 0.6);
    border-radius: 25.3471px;
    border-radius: 15px;
    padding: 18px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    /* font-family: 'Lato', sans-serif; */
}

.hm-icon {
    width: 30px;
    margin-right: 14px;
}

.hml-title {
    width: calc(100% - 94px);
}

.main-home-card .form-card {
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(221, 209, 255, 0.6);
}

.resources-slider.owl-carousel .owl-item img {
    max-width: 100% !important;
}

.badge {
    background: #EEE9FC;
    width: 26px;
    height: 26px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #948FB5;
    border-radius: 50%;
    padding: 0;
    margin-right: 15px;
    min-width: 26px;
}

.badge.active {
    background: #FF6624;
    color: #fff;
}

.idil-btn {
    padding-left: 35px;
    padding-right: 35px;
}

.custom-card {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.common-left {
    min-width: 330px;
}

.common-left .custom-card {
    min-height: calc(100vh - 85px);
    position: sticky !important;
    top: 80px;
    padding-bottom: 69px;
}

.common-right {
    width: calc(100% - 330px);
    padding-left: 15px;
}

.common-right>.custom-card {
    min-height: calc(100vh - 85px);
}

.left-menu {
    overflow-x: auto;
    max-height: calc(100vh - 153px);
}

.left-menu li a {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.3em;
    color: #948FB5;
    padding: 20px 22px;
    display: block;
}

.left-menu li a.active {
    background: #F3F4F9;
    color: #0000FF;
    font-weight: 700;
}

.lmenu-icon {
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: -10px;
    margin-right: 10px;
}

.svg-icon {
    vertical-align: 2px;
    text-align: center;
}

.svg-icon .bi {
    font-size: 22px;
    vertical-align: middle;
    color: #948FB5;
}

.left-menu li a.active .svg-icon .bi {
    color: #0000FF;
}

.svg-icon svg {
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.home-icon {
    background: url(../img/home-icon.svg) no-repeat center center;
    background-size: 100%;
}

.left-menu a.active .home-icon,
.ud-menu li a.active .home-icon {
    background: url(../img/home-icon-hover.svg) no-repeat center center;
    background-size: 100%;
}

.deals-icon {
    background: url(../img/deals-icon.svg) no-repeat center center;
    background-size: 100%;
}

.left-menu a.active .deals-icon,
.ud-menu li a.active .deals-icon {
    background: url(../img/deals-icon-hover.svg) no-repeat center center;
    background-size: 100%;
}

.portfolio-icon {
    background: url(../img/portfolio-icon.svg) no-repeat center center;
    background-size: 26px 27px;
}

.left-menu a.active .portfolio-icon,
.ud-menu li a.active .portfolio-icon {
    background: url(../img/portfolio-icon-hover.svg) no-repeat center center;
    background-size: 26px 27px;
}

.resource-icon {
    background: url(../img/resources-icon.svg) no-repeat center center;
    background-size: 26px 27px;
}

.left-menu a.active .resource-icon,
.ud-menu li a.active .resource-icon {
    background: url(../img/resources-icon-hover.svg) no-repeat center center;
    background-size: 26px 27px;
}

.about-icon {
    background: url(../img/about-icon.svg) no-repeat center center;
    background-size: 26px 27px;
}

.left-menu a.active .about-icon,
.ud-menu li a.active .about-icon {
    background: url(../img/about-icon-hover.svg) no-repeat center center;
    background-size: 26px 27px;
}

.contact-icon {
    background: url(../img/contact-icon.svg) no-repeat center center;
    background-size: 22px 22px;
}

.left-menu a.active .contact-icon,
.ud-menu li a.active .contact-icon {
    background: url(../img/contact-icon-hover.svg) no-repeat center center;
    background-size: 22px 22px;
}

.logout-icon {
    background: url(../img/logout-icon.svg) no-repeat center center;
    background-size: 22px 22px;
}

.left-menu a.active .logout-icon,
.ud-menu li a.active .logout-icon {
    background: url(../img/logout-icon-hover.svg) no-repeat center center;
    background-size: 22px 22px;
}

.gray-btop-10 {
    border-top: solid 10px #EEE9FC;
}

.text-gray {
    color: #777 !important;
}

.font-10 {
    font-size: 10px !important;
}

.di-sub-label {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14.4px;
    line-height: 17px;
    color: #948FB5;
}

.di-sub-value {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16.2px;
    line-height: 20px;
    color: #333333;
}

.detail-dtl-list .form-card+.form-card {
    margin-top: 15px;
}

.dl-heading {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14.4px;
    line-height: 17px;
    color: #948FB5;
}

/*  */
.user-dropdown {
    border: none;
    background: transparent;
}

.ud-name {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #0000FF;
}

.ud-name {
    margin-left: 10px;
}

.ud-arrow {
    background: url(../img/gray-down-arrow.svg) no-repeat center center;
    background-size: 100%;
    width: 16px;
    height: 10px;
    margin-left: 10px;
    display: inline-block;
    margin-top: 4px;
}

.ud-block {
    position: relative;
}

.ud-menu {
    position: absolute;
    right: 0;
    top: 110%;
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    padding: 0;
    border-radius: 5px;
    width: 180px;
    display: none;
}

.ud-block .ud-menu.show {
    display: block;
}

.ud-menu:before {
    content: "";
    position: absolute;
    right: 25px;
    top: -7px;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
    border-bottom: solid 7px #fff;
}

.ud-menu li a {
    text-align: left !important;
    padding: 7px 15px;
    display: block;
}

.ud-for-mobile {
    display: none;
}

.ud-menu li a .lmenu-icon {
    margin-right: 4px;
}

.mobile-menuicon {
    border: none;
    background: transparent;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 10%); */
    margin-right: 0;
    display: none;
    position: relative;
    left: -10px;
}

.mobile-menuicon img {
    max-width: 28px;
}


.demo-server-block {
    width: 100%;
    padding: 5px;
    background-color: #0000FF;
    text-align: center;
    font-size: 12px;
    color: #fff;
}

.hm-arrow img {
    display: block;
    min-width: 9px;
}

.change-amount-btn,
.confirm-amount-btn {
    line-height: normal;
    font-size: 13px;
}

.change-amount-btn br,
.confirm-amount-btn br {
    display: none;
}

.info-amount {
    font-size: 14px;
    color: #FF6624;
}

.info-amount img {
    max-width: 20px;
    vertical-align: -5px;
    margin-right: 1px;
}

.balance-fund-box {
    border-radius: 20px;
    border: 2px solid var(--purple, #EEE9FC);
    background: var(--white, #FFF);
    padding: 10px 14px;
}

.wallet-icon {
    background: url(../img/removebg-preview.svg) no-repeat center center;
    background-size: 100%;
    width: 40px;
    height: 40px;
    margin-right: 12px;
}

.form-check-input:focus {
    box-shadow: none;
}

.not-interest-text {
    font-size: 14px;
    color: #D32F2F;
}

.cursor-pointer {
    cursor: pointer;
}

.unallocated-text {
    font-size: 14px;
    color: #0000ff;
    margin-top: 10px;
    display: inline-block;
    line-height: 22px;
}

.blueInfoIcon {
    background: url(../img/information-line.svg) no-repeat center center;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: 100%;
    vertical-align: -5px;
    margin-right: 1px;
}

.page-title {
    color: #0000FF;
    font-weight: 700;
    font-size: 1.3em;
}

.logout-menu {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.logout-menu a {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.5em;
    color: #948FB5;
    padding: 20px 22px;
    display: block;
}

.deal-info-block .dib-left-box {
    border-right: solid 1px #eee9fc;
}

.font-0-7em {
    font-size: 0.7em !important;
}

.dtl-info-box {
    width: 95px;
    margin-right: 0;
}

.pitch-info-box {
    width: 120px;
    margin-right: 0;
}

.dtl-info {
    gap: 6px
}

.dtl-info .col-info-box {
    width: 120px;
}

.w-40 {
    width: 40% !important;
}

.my-profile-card li .btn-sm {
    font-size: 12px;
}

.my-profile-card li a {
    height: 68px;
}

.theme-btn.btn-sm {
    font-size: 0.8em;
    padding: 0 15px;
    line-height: 32px;
    height: 36px;
}

.xs-title {
    font-size: 0.95em;
    color: #333333;
}

.favourite-list ul {
    padding: 0;
    margin: 0;
}

.favourite-list li {
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.statement-icon img {
    max-width: 130px;
}

.sm-btn {
    font-size: 14px;
    padding: 10px 15px;
}

.ml-auto {
    margin-left: auto;
}

.portfolio-card {
    background: #FFFFFF;
    border: 0px solid #EEE9FC;
    box-shadow: 0px 5.06942px 25.3471px rgba(221, 209, 255, 0.6);
    border-radius: 25.3471px;
    border-radius: 15px;
    padding: 18px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    border-left: solid 7px transparent;
}

.portfolio-card+.portfolio-card {
    margin-top: 15px;
}

.portfolio-card .circle-box {
    width: 50px;
    height: 50px;
    min-width: 50px;
}

.filter-selector .custom-form .form-control {
    padding: 9px 34px 9px 15px;
    font-size: 0.9rem;
    width: 120px;
}

.mxw-300 {
    max-width: 300px;
    margin: 0 auto;
}

.filter-btn {
    background: #f6f5fd;
    border: none;
    padding: 6px 11px;
    border-radius: 8px;
    margin-left: 10px;
}

.filter-btn i {
    color: #333;
}

.custom-tabs .nav-tabs {
    border-bottom: none;
}

.custom-tabs .nav-tabs li+li {
    margin-left: 4px;
}

.custom-tabs .nav-tabs .nav-link {
    background: #f6f5fd;
    border: solid 1px #fff;
    border-bottom: none;
    border-radius: 0;
    color: #333;
    margin-bottom: 0;
}

.custom-tabs .nav-tabs .nav-link.active {
    border: solid 1px #e1e1e1;
    border-bottom: none;
    bottom: -1px;
    position: relative;
}

.custom-tabs .tab-content {
    border: solid 1px #e1e1e1;
    padding: 15px 20px 25px;
}

.close-btn {
    padding: 12px 15px;
}

.cc-common-card .cr-heading {
    padding: 17px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pt-icon {
    max-width: 60px;
    margin: 0 auto 10px;
    min-height: 60px;
}

.portfolio-card-list .accordion-button {
    padding: 0;
    background: transparent;
}

.portfolio-card-list .accordion-button::after {
    display: none;
}

.portfolio-card-list .portfolio-card {
    padding: 0;
    border-radius: 15px !important;
}

.portfolio-card-list .portfolio-card .accordion-button {
    padding: 13px 20px;
}

.portfolio-card-list .accordion-collapse {
    border: none;
}

.portfolio-card-list .accordion-collapse.show {
    border-top: 1px solid #EEE9FC;
}

.portfolio-card-list .cs-accordion .accordion-button {
    background: #f6f5fd;
    color: #333333;
    box-shadow: none;
    border-radius: 10px 10px 0 0 !important;
}

.portfolio-card-list .cs-accordion .accordion-collapse {
    border: 1px solid #eee9fc;
    border-top: none;
    border-radius: 0 0 10px 10px !important;
}

.font-14 {
    font-size: 14px !important;
}

.gray-text {
    color: #948FB5 !important;
}

.blue-box {
    padding: 30px;
    background-color: #0000FF;
    border-radius: 15px;
}

.extra-link-actions {
    position: absolute;
    right: 8px;
    top: 15px;
}

.extra-link-actions .ela-action {
    padding: 0 7px;
    display: inline-block;
    vertical-align: middle;
}

.extra-link-actions .ela-action .bi {
    font-size: 20px;
    color: #948FB5;
}

.extra-link-actions .ela-action .bi-star-fill {
    color: #FF6624;
}

.extra-link-actions .ela-action .bi.bi-eye {
    font-size: 25px;
}

.extra-link-actions .custom-dropdown .btn {
    outline: none;
    padding: 5px 11px 5px 5px;
    border: none;
}

.extra-link-actions .custom-dropdown .btn:focus {
    outline: none;
    border: none;
}

.user-dropdown.dropdown-toggle::after {
    display: none;
}

.received-amount {
    color: #948FB5;
}

.received-amount img {
    max-width: 20px;
    vertical-align: -5px;
    margin-right: 1px;
}

.add-investment-btn {
    color: #FF6624;
    border: none;
    background: transparent;
    font-size: 15px;
    font-weight: 700;
}

.pfr-table table {
    /* border: solid 1px #cfcfcf; */
    border-left: none;
    border-radius: 5px;
}

.pfr-table tr th {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    background: #f6f5fd;
    padding: 16px 15px;
    /* border-left: solid 1px #EEE9FC;
    border-top: solid 1px #EEE9FC; */
}

.pfr-table tr td {
    font-weight: 600;
    font-size: 16px;
    color: #948FB5;
    border-radius: 5px;
    padding: 10px 15px;
    border-bottom: solid 1px #EEE9FC;
    border-left: solid 1px #EEE9FC;
}

.pfr-table tr td:last-child {
    border-right: solid 1px #EEE9FC;
}

.pfr-table tr th:first-child,
.pfr-table tr:last-child td:first-child {
    border-radius: 10px 0 0 0;
}

.pfr-table tr th:last-child,
.pfr-table tr:last-child td:last-child {
    border-radius: 0 10px 0 0;
}



.swd-accordion.portfolio-card-list .accordion-button::after {
    display: block;
}

.swd-accordion .portfolio-card {
    border-left: none;
}

.vertical-tabs.custom-tabs {
    border-bottom: solid 1px #e1e1e1;
}

.vertical-tabs.custom-tabs .nav-tabs {
    width: 180px;
}

.vertical-tabs.custom-tabs .nav-tabs .nav-link {
    width: 100%;
    text-align: left;
    background: #ebe9f5;
}

.vertical-tabs.custom-tabs .nav-tabs li+li {
    margin-left: 0;
}

.vertical-tabs.custom-tabs .tab-content {
    width: calc(100% - 132px);
    min-height: 290px;
    border-bottom: none;
}

.vertical-tabs.custom-tabs .nav-tabs .nav-link.active {
    border: none;
    bottom: 0;
    background: #f6f5fd;
}

.close-pos-btn {
    position: absolute;
    right: 5px;
    top: 5px;
}

/* .deal-activity-flow {
    position: sticky;
    top: 172px;
} */
button.ela-action {
    background: transparent;
    border: none;
    padding: 0px;
}

button.ela-action.selected {
    color: #FF6624;
}

.distribution-item {
    padding: 0 0 30px;
    position: relative;
    padding-left: 24px;
}

.distribution-circle {
    position: absolute;
    left: 2px;
    top: 2px;
    background: #0d942b;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.back-btn {
    background: transparent;
    border: none;
    vertical-align: -3px;
    margin-right: 5px;
    padding-left: 0;
}

.back-btn img {
    max-width: 26px;
    display: block;
}

.profile-circle {
    width: 100px;
    height: 100px;
    background: #EBE0FE;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.3em;
    color: #552FBA;
    text-align: center;
    overflow: hidden;
}

.profile-circle img {
    width: 100px;
    height: 100px;
}

.pf-edit-btn {
    background: #ff6624;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: none;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    right: 3px;
    bottom: 1px;
    overflow: hidden;
}

.pf-edit-btn img {
    max-width: 14px;
}

.pf-edit-btn .bi {
    color: #fff;
}

.dashfile-upload-btn {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.dashfile-upload-btn:focus {
    outline: none;
    border: none;
}

.profile-circle-header {
    width: 38px;
    height: 38px;
    background: #EBE0FE;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
}

.sep-faq-accordion .accordion-button {
    background: #fff;
    border: solid 2px #f6f5fd;
}

.sep-faq-accordion .accordion-collapse {
    background: #fff;
    border: solid 2px #f6f5fd;
    border-top: none;
}

.text-lgray {
    color: #948FB5 !important;
}

.gray-bg {
    background-color: #777;
    padding: 4px 9px;
    border-radius: 17px;
}

.font-12 {
    font-size: 12px !important;
}

.view-doc-box {
    position: relative;
}

.view-doc-box:before {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    top: 0;
    border-top: solid 1px #eee9fc;
}

.view-doc-btn {
    background: transparent;
    border: none;
    font-weight: 700;
    font-size: 14px;
    color: #948FB5;
    text-decoration: underline;
}

.lgray-bg {
    background-color: #e1e1e1;
    padding: 4px 9px;
    border-radius: 17px;
}

.info-btn {
    background: transparent;
    border: none;
    margin-left: 3px;
}

.uploaded-box {
    max-height: 350px;
    max-width: 100%;
}

.uploaded-box>img {
    max-width: 100%;
    max-height: 350px;
}

.cp-mxh-calc {
    max-height: calc(100vh - 182px);
    overflow-y: auto;
    padding-right: 10px;
}

.cp-mxh-calc::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}

.cp-mxh-calc::-webkit-scrollbar-track {
    background: #777;
    border-radius: 3px;
}

.cp-mxh-calc::-webkit-scrollbar-thumb {
    background-color: #DF3D0F;
    border-radius: 3px;
}

.theme-btn.btn {
    padding: 0 10px;
}

.ela-action {
    color: #333;
}

.profile-svg .bi {
    font-size: 26px;
}

.font-14 {
    font-size: 14px !important;
}

.faq-tag {
    margin-right: 15px;
}

.faq-tag img {
    max-height: 28px;
}

.startup-profile-header .ela-action .bi {
    font-size: 22px;
    color: #948FB5;
}

.dtl-top-info .circle-box {
    margin: 0 auto 10px;
    width: 120px;
    height: 120px;
    min-width: 120px;
}

.dtl-top-info .md-title {
    font-weight: 700;
    font-size: 1.3em;
}

.rating-stars .bi {
    color: #948FB5;
    font-size: 20px;
}

.rating-stars .bi+.bi {
    margin-left: 4px;
}

.rating-stars .bi-star-fill {
    color: #FF9900;
}

.extra-link-actions .faq-tag {
    margin-right: 1px;
}

.extra-link-actions .faq-tag img {
    max-width: 25px;
}

.extra-link-actions .wishlist-action {
    vertical-align: -2px;
}

.w-180 {
    width: 180px;
}

.syndicate-name-tag {
    background: #F6F5FD;
    color: #948FB5;
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    padding: 5px 16px;
    border-radius: 12px;
}

.mr-10 {
    margin-right: 10px !important;
}

.unrealised-text h1,
.unrealised-text p {
    font-family: 'Lato', sans-serif !important;
}

.ah-status-tag {
    background: #948FB5;
    color: var(--Grey, #F6F5FD);
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    padding: 5px 16px;
    border-radius: 14px;
}

.circle-text {
    text-align: center;
    position: relative;
}

.ct-name {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
}

.filter-btn .bi-funnel-fill {
    color: #ff6624;
}

.cs-admin-dropdown ul {
    padding-left: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cs-admin-dropdown li {
    list-style-type: none !important;
}

.cs-admin-dropdown .ud-menu:before {
    right: 13px;
}

.cs-admin-dropdown .dropdown-item.active,
.cs-admin-dropdown .dropdown-item:active,
.cs-admin-dropdown .dropdown-item:focus,
.cs-admin-dropdown .dropdown-item:hover {
    background: #EEE9FC;
    color: #333;
}

.startup-acc-btn .accordion-button::after {
    display: none;
}

.addround-tabs-btns .addround-btn {
    border: none;
    background: transparent;
    margin: 0 50px;
    color: #616161;
    position: relative;
}

.addround-tabs-btns .addround-btn:after {
    content: "";
    position: absolute;
    left: calc(100% + 10px);
    width: 80px;
    background: #bdbdbd;
    height: 1px;
    top: 50%;
    margin-top: -1px;
}

.addround-tabs-btns .addround-btn:last-child:after {
    display: none;
}

.favourite-box .custom-form .form-control {
    padding: 11px 20px;
}

.addround-step-count {
    width: 34px;
    height: 34px;
    background: #9e9e9e;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 50%;
    line-height: 34px;
    color: #fff;
}

.addround-tabs-btns .addround-btn.active .addround-step-count {
    background: #0000ff;
}

.addround-header {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.addround-md-title {
    width: calc(100% - 250px);
}

.font-1-5em {
    font-size: 1.5em !important;
}

.investment-info-list {
    padding-top: 10px;
}

.investment-info-list li {
    padding: 3px 0 3px 20px;
    position: relative;
    color: #948FB5;
}

.investment-info-list li:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 12px;
    width: 7px;
    height: 7px;
    background: #f35429;
    border-radius: 11px;
}

.rfl-btn-box {
    min-width: 100px;
}

.mxw-400 {
    max-width: 400px;
}

.no-portfolio-box {
    background: url(../img/portfolio-no-data.png) no-repeat center center;
    background-size: 100%;
    width: 100%;
    height: calc(100vh - 180px);
}

.no-portfolio-pos {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}

.mb-mxw-400 {
    max-width: 500px;
    margin: 0 auto;
}

.small-btn {
    padding-left: 15px;
    padding-right: 15px;
    height: 36px !important;
    line-height: 33px;
    text-decoration: none !important;
    font-size: 0.8em !important;
}

.theme-text {
    color: #ff6624 !important;
}

.fill-gray-btn {
    background: #948FB5;
    border: solid 2px #948fb5;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1em;
    color: #fff;
    transition: all 0.5s ease-in-out 0s;
    padding-left: 15px;
    padding-right: 15px;
    height: 36px;
    line-height: 33px;
    text-decoration: none !important;
    font-size: 0.8em;
}

.fill-gray-btn:hover {
    background: #948fb5;
    color: #333;
}

.terms-accept-btn {
    line-height: 38px;
}

/* complaint end */
.container h6 {
    font-size: 24px;
    color: #ff6624;
    margin-bottom: 10px;
    font-weight: 700;
}

.smartodr p {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
}

.smartodr a {
    color: #0000FF;
    font-weight: 600;
    text-decoration: underline;
}

.container p,
a {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
}

.complaint-link {
    color: #0000FF;
    text-decoration: underline;
}

.email p {
    margin-bottom: 0;
}

.sigCanvas {
    border-radius: 10px;
    border: solid 1px #948fb5;
    padding: 5px;
    background-color: #ffffff;
    height: 200px;
    width: 20rem;
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.edit-mode {
    background-color: #F9C236;
    padding: 2px 5px;
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
}

.sign-preview {
    width: 50px;
    margin-right: 14px;
}

.pdf-viewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh;  */
}

.pdf-container {
    max-width: 800px;
    width: 100%;
    height: 100%;
    position: relative;
}

.pdf-page {
    /* max-width: 100%; */
    height: auto;
}

.page-navigation {
    /* text-align: center; */
    margin-top: 0px;
    align-items: center;
    justify-content: space-around;

}

.signature-overlay {
    position: absolute;
    /* top: 85%;  */
    /* left: 56%;  */
    height: 3%;
    width: 6%;
    /* border-radius: 2px; */
    /* z-index: 999; */
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25px, 1fr));
    grid-gap: 15px;
}

.item {
    min-height: 25px;
    border: #FF6624 1px solid;
    border-radius: 10px;
    padding: 5px 5px;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    background-color: rgba(255, 102, 36, 0.1);
    color: #948FB5;
}

.selected-item {
    background-color: #FF6624;
    color: #FFF;
}

.waiting {
    text-align: end;
    margin-right: 20px;
}

.waiting-text {
    color: #F7941F;
}

.sign-document-btn {
    background: rgba(247, 148, 31, 0.2);
    border: 1px solid #F7941F;
    color: #F7941F;
}

.loader {
    margin-top: 50vh;
}

.common-modal::-webkit-scrollbar {
    display: none;
}